<div
  [ngStyle]="{'--back-to-top-button-right-offset-multiplier': isVisible ? '1' : '-3'}"
  class="fixed size-[4.25rem] bottom-6 right-[calc(1.5rem*(var(--back-to-top-button-right-offset-multiplier)))] 2xl:bottom-20
         2xl:right-[calc(5rem*(var(--back-to-top-button-right-offset-multiplier)))] z-50 flex items-center justify-center
         transition-[right] duration-1000"
>
  <svg class="w-full h-full" viewBox="0 0 100 100">
    <circle
      class="fill-dark-void dark:fill-dark-void-light stroke-transparent"
      stroke-width="6"
      cx="50"
      cy="50"
      [attr.r]="radiusOuterCircle"
    ></circle>
    <circle
      class="fill-transparent stroke-vanilla-light transition-[stroke-dashoffset] ease-out duration-500 -rotate-90 origin-[50%_50%]"
      stroke-width="4"
      stroke-linecap="round"
      cx="50"
      cy="50"
      [attr.r]="radiusInnerCircle"
      [attr.stroke-dasharray]="circumference"
      [attr.stroke-dashoffset]="strokeDashoffset"
    ></circle>
  </svg>
  <button
    class="absolute size-12 flex items-center justify-center bg-transparent rounded-full"
    (click)="navigateToTop()" aria-label="Zurück zum Seitenanfang">
    <fa-icon [icon]="faCaretUp" size="2xl" class="text-vanilla"></fa-icon>
  </button>
</div>

