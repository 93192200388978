<section class="max-w-[unset] px-0 pb-0">
  <div class="container mx-auto px-8">
    <h2>Favoriten</h2>
    <div class="divider"></div>
  </div>
  <div id="favorites-scroll-trigger" class="relative overflow-hidden *:w-full">
    <section [ngStyle]="{
      '--favorites-grid-wrapper-z-index': showFavoritesOverlay ? '30' : '10',
    }"
      class="absolute h-svh landscape:h-lvh inset-x-0 px-5 z-[--favorites-grid-wrapper-z-index]">
      <div [ngStyle]="{
      '--favorites-child-opacity': showFavoritesOverlay ? '1' : '0',
      '--favorites-child-top': showFavoritesOverlay ? '0' : '100%'
    }"
           class="h-full flex flex-col gap-5 items-center *:flex *:flex-auto *:items-center *:relative *:max-w-full *:overflow-hidden">
        <div></div>
        <app-favorites-overlay
          class="w-96 2xl:w-[35rem] opacity-[--favorites-child-opacity] top-[--favorites-child-top] transition-[opacity,top] duration-[1500ms]"></app-favorites-overlay>
        <app-footer
          class="!shrink-0 opacity-[--favorites-child-opacity] top-[--favorites-child-top] transition-[opacity,top] delay-500 duration-[1500ms]"></app-footer>
      </div>
    </section>
    <div id="favorites-image-section" class="relative h-lvh">
      <img [ngSrc]="selectedBackgroundImagePath"
           ngSrcset="2560w, 1920w" sizes="100vw" fill
           [loaderParams]="{dynamicWidth: true, imageFormat: 'webp'}" priority
           class="object-cover" alt="Landschaft mit weitem Blick in die Ferne.">
    </div>
    <div [ngStyle]="{
      '--favorites-wrapper-z-index': showFavoritesOverlay ? '10' : '20',
      '--favorites-outline-width': outlineWidth,
      '--favorites-outline-offset': outlineOffset
    }"
         class="absolute h-lvh flex flex-col top-0 z-[--favorites-wrapper-z-index]">
      <div class="relative size-full max-h-full max-w-full [perspective:500px]">
        <img #scrollImage *ngIf="selectedForegroundImage"
             [ngSrc]="selectedForegroundImage"
             ngSrcset="2560w, 1920w" sizes="100vw" fill
             [loaderParams]="{dynamicWidth: true, imageFormat: 'webp'}" priority
             id="favorites-image-overlay"
             [ngStyle]="{
                '--favorites-img-z-index': showFavoritesOverlay ? '20': '30'
             }"
             class="!size-auto max-h-full max-w-full m-auto object-contain z-[--favorites-img-z-index]
                    outline outline-snow dark:outline-dark-void outline-[calc(var(--favorites-outline-width)*1px)]
                    outline-offset-[calc(var(--favorites-outline-offset)*-1px)] will-change-transform"
             alt="Retro-Fernseher mit ausgeschnittenem Bildschirm."
             (load)="afterForegroundImageLoad()">
        <img *ngIf="notSelectedForegroundImage" [ngSrc]="notSelectedForegroundImage"
             ngSrcset="2560w, 1920w" sizes="100vw" fill
             [loaderParams]="{dynamicWidth: true, imageFormat: 'webp'}"
             class="absolute !size-auto invisible"
             alt="Retro-Fernseher mit ausgeschnittenem Bildschirm." aria-hidden="true">
      </div>
    </div>
  </div>
</section>
