<section class="px-5 py-0">
  <div #scrollTrigger [ngStyle]="{
    '--about-me-content-length': contentLength,
    '--about-me-buffer': buffer,
    '--about-me-ppc': ppc,
    '--about-me-pad': pad
  }"
       id="about-me-scroll-trigger"
       class="h-[calc(((var(--about-me-ppc)*var(--about-me-content-length)+(var(--about-me-pad)*var(--about-me-buffer)))*1px)+100vh)]"
  >
    <div
      class="sticky top-0 min-h-lvh grid place-items-center
             my-0 mx-auto [font-size:40px] leading-none lg:text-7xl font-bold"
    >
      <h2 class="hidden">{{ secondSplitText }} {{ thirdSplitText }} {{ fourthSplitText }}</h2>
      <div class="text-center bg-gradient-to-br from-vanilla-extra-dark dark:from-vanilla-light
                  to-dark-void-dark dark:to-snow bg-clip-text">
        <app-about-me-detail *ngFor="let detailListEntry of detailList"
                             [scrollTrigger]="scrollTrigger"
                             [detail]="detailListEntry"
                             [ppc]="ppc"
        ></app-about-me-detail>
      </div>
    </div>
  </div>
</section>
