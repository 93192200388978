<span [ngStyle]="{
  '--about-me-span-active': active,
  '--about-me-span-text-color': active ? 'transparent' : 'inherit'
}"
      [ngClass]="{'italic': detail.italic, 'bg-gradient-to-r from-transparent to-transparent': !active}"
      [attr.aria-hidden]="!active"
      class="text-[--about-me-span-text-color]
             transition-[color] duration-700 ease-out
             opacity-[calc(0.1+var(--about-me-span-active))]"
>{{ detail.text }}</span>
<span *ngIf="detail.endOfLine || detail.endOfSection" [ngClass]="{'mt-16': detail.endOfSection}" class="block" aria-hidden="true"></span>
